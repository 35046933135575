<template>
  <div class="welcome">
    <div class="welcome__logo">
      <img
        src="@/assets/images/ahn-logo.svg"
        alt="ahn logo"
        width="203"
        height="64"
      />
    </div>
    <div class="modal__body contact-us__body">
      <div class="welcome__body">
        <h3 class="welcome__title">
        Assessing the use of voice based mobile technology with instant biofeedback 
        and a mobile digital health application to track and manage medication 
        adherence and pulmonary function testing (PFT) spirometry in adult COPD patients, 
        and mobile digital health application: a remote observational study
        </h3>
        <p>Welcome to the study site.</p>
        <p>
          Press “Continue” below to review the study Informed Consent Form. The
          Study Coordinator will be happy to answer your questions.
        </p>

        <div class="welcome__section">
          <h4>Supported mobile platforms:</h4>
          <div>Chrome on Android devices.</div>
          <div>Safari on iOS devices.</div>
        </div>

        <div class="welcome__section">
          <h4>Desktop users:</h4>
          <div>Please note that Internet Explorer is not supported</div>
        </div>
      </div>
      <div class="welcome__footer">
        <button
          class="button button_secondary button_md"
          @click="$emit('close')"
        >
          <span>Continue</span>
          <span class="icon icon__arrow icon__arrow_right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { isSupportedBrowser, isIOS } from "@/utils/system";
import { POPUP_NOTIFICATION } from "@/constants";

export default {
  inject: {
    showModal: { default: () => {} },
  },

  beforeDestroy() {
    if (isSupportedBrowser) return;
    const title = isIOS
      ? "You are using an unsupported browser. Please use Safari."
      : "You are using an unsupported browser. Please use Chrome.";
    this.showModal(POPUP_NOTIFICATION, { title });
  },
};
</script>
